interface IProps {
  error: any;
  resetErrorBoundary: (...args: any[]) => void;
}

const ErrorFallback = ({ error, resetErrorBoundary }: IProps) => {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try Again</button>
    </div>
  );
};

export default ErrorFallback;
