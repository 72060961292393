import React from "react";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { styled, useTheme } from "@mui/material/styles";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export interface CommonProps {
  open: boolean;
  onClose?: () => void;
  onClick?: () => void;
  title: string;
  content: React.ReactNode;
}

const RightDrawer: React.FC<CommonProps> = (props) => {
  const { open, onClose, title, content } = props;
  const theme = useTheme();

  return (
    <div className="right-drawer">
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Main open={open}>
          <DrawerHeader />
        </Main>
        <Drawer
          className="right-drawer-default"
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {},
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
          <DrawerHeader className="header">
            <div className="side-bar-title">{title}</div>
            <IconButton onClick={onClose}>
              {theme.direction === "rtl" ? <CancelIcon /> : <CancelIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {content}
        </Drawer>
      </Box>
    </div>
  );
};
export default RightDrawer;
