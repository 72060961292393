import React from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import "./Drawer.scss";
import "../../assets/styles/Utils.scss";

import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";

import {
  Box,
  Drawer as MUI_Drawer,
  Toolbar,
  IconButton,
  CssBaseline,
  Divider,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Avatar, Collapse, Badge, Menu, MenuItem } from "@mui/material";

import {
  Close as CloseIcon,
  ViewQuilt as ViewQuiltIcon,
  Equalizer as EqualizerIcon,
} from "@mui/icons-material/";
import {
  Portrait as PortraitIcon,
  InfoRounded as InfoRoundedIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material/";
import {
  NotificationsNone as NotificationsNoneIcon,
  NotificationsActive as NotificationsActiveIcon,
} from "@mui/icons-material/";
import {
  ArrowDropDown as ArrowDropDownIcon,
  LocalLibrary as LocalLibraryIcon,
  Tour as TourIcon,
} from "@mui/icons-material/";

import Logo1 from "../../image/sip_long_logo.png";
import Logo2 from "../../image/sip_logo.png";
import LogoGaSmall from "../../assets/images/ga/logo-ga-small.png";
import LogoGaBig from "../../assets/images/ga/ga-logo-big.svg";

import BreadcrumbFile from "./breadcrumbFile";
import RightDrawer from "./RightDrawer";
import BellNotification from "../sendNotification/BellNotification";

const drawerWidth = 278;
const center_code: any = localStorage.getItem("center_code");
let programName: any = window.localStorage.getItem("theme");

const Users =
  center_code === "SABG" || center_code === "sabg"
    ? [
        {
          name: "Dashboard",
          icon: <ViewQuiltIcon />,
          subMenu: [],
          path: "/dashboard",
        },
        {
          name: "Master",
          icon: <PortraitIcon />,
          subMenu: [
            { name: "User Master", path: "/user-master" },
            { name: "User Role", path: "/user-role" },
            { name: "Role Access", path: "/role-access" },
            { name: "Franchise Master", path: "/franchise-master" },
            { name: "Item Master", path: "/item-master" },
            { name: "Franchise Group", path: "/franchise-group" },
            { name: "Promo Code", path: "/promo-code" },
          ],
        },
        {
          name: "Student",
          icon: <LocalLibraryIcon />,
          subMenu: [
            { name: "Certificate Request", path: "/certificate-request" },
          ],
        },
        {
          name: "Purchase",
          icon: <TourIcon />,
          subMenu: [
            { name: "SMS Order", path: "/sms-order" },
            { name: "Kit Outstanding", path: "/kit-outstanding" },
            { name: "Customer Discount", path: "/customer-discount" },
            {
              name: "Internal Purchase Order",
              path: "/internal-purchese-order",
            },
            {
              name: "Dispatch Purchase Order",
              path: "/dispatch-purchese-order",
            },
            {
              name: "Purchase Return",
              path: "/purchase-return",
            },
            {
              name: "Payment",
              path: "/payment",
            },
            { name: "Redeem Point Order", path: "/redeem-point-order" },
          ],
        },
        {
          name: "Reports",
          icon: <EqualizerIcon />,
          subMenu: [{ name: "Manage Reports" }, { name: "View Request" }],
        },
        {
          name: "Notifications",
          icon: <NotificationsActiveIcon />,
          subMenu: [{ name: "Send Notification", path: "/send-notification" }],
        },

        {
          name: "Settings",
          icon: <SettingsIcon />,
          subMenu: [
            { name: "Feedback", path: "/feedback" },
            { name: "Admin Settings", path: "/admin-settings" },
          ],
        },
        {
          name: "Help & FAQ",
          icon: <InfoRoundedIcon />,
          subMenu: [],
        },
      ]
    : [
        {
          name: "Dashboard",
          icon: <ViewQuiltIcon />,
          subMenu: [],
          path: "/dashboard",
        },
        {
          name: "Master",
          icon: <PortraitIcon />,
          subMenu: [
            { name: "Batch", path: "/batch-list" },
            { name: "Course Instructor", path: "/course-instructor" },
            { name: "Master User", path: "/user-master" },
          ],
        },
        {
          name: "Student",
          icon: <LocalLibraryIcon />,
          subMenu: [
            { name: "Leads", path: "/student-lead-management" },
            { name: "Registration/ Master", path: "/student-master" },
            { name: "Invoice", path: "/student-invoice" },
            { name: "Payment Gateway ", path: "/payments-gateway" },
            { name: "Certificate", path: "/certificate-booking-printing" },
            { name: "Attendance", path: "/student-attendance" },
            programName === "sa"
              ? { name: "AGC/Prodigy Student Registration" }
              : null,
          ],
        },
        {
          name: "Purchase",
          icon: <TourIcon />,
          subMenu: [
            { name: "Order", path: "/order" },
            { name: "Purchase Return", path: "/purchase-return" },
            { name: "Kit Outstanding", path: "/outstanding" },
            { name: "Payment Summary", path: "/payment-summary" },
            { name: "Redeem Point Order", path: "/student-redeem-point-order" },
            {
              name: "Dispatch Redeemed Product",
              path: "/dispatch-redeemed-product",
            },
          ],
        },
        {
          name: "Reports",
          icon: <EqualizerIcon />,
          subMenu: [
            { name: "Manage Reports", path: "/report" },
            { name: "View Request" },
          ],
        },
        {
          name: "Notifications",
          icon: <NotificationsActiveIcon />,
          subMenu: [
            { name: "Send Email or SMS", path: "/send-email-sms" },
            { name: "Templates", path: "/template" },
            { name: "Send History", path: "/sent-history" },
          ],
        },
        {
          name: "Help & FAQ",
          icon: <InfoRoundedIcon />,
          subMenu: [],
        },
      ];

if (window.localStorage.getItem("theme")) {
  programName = window.localStorage.getItem("theme");
}
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  color: "#000",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MUI_Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [drawerExpand, setDrawerExpand] = React.useState(false);
  const [openSubmenu, setOpenSubmenu] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [expandedIndex, setexpandedIndex] = React.useState(-1);
  const [expandedIndexSubmenu, setexpandedIndexSubmenu] = React.useState(-1);
  const [notificationDrawerOpen, setNotificationDrawerOpen] =
    React.useState(false);

  const handleNotificationDrawerOpen = () => {
    setNotificationDrawerOpen(!notificationDrawerOpen);
  };

  const handleNotificationDrawerClose = () => {
    setNotificationDrawerOpen(false);
  };

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDrawerOpen = () => {
    setDrawerExpand(true);
  };

  const handleDrawerClose = () => {
    setDrawerExpand(false);
    setOpenSubmenu(false);
  };
  const handleSubmenuClick = (val: any, i: any) => {
    navigate(val.path);
    setexpandedIndexSubmenu(i);
    // setexpandedIndex(-1);
    setDrawerExpand(!drawerExpand);
    setOpenSubmenu(!openSubmenu);
  };

  const handleClick = (index: any, subItem: any, path: any) => {
    if (subItem.length > 0) {
      // setOpenSubmenu(!openSubmenu);
      setOpenSubmenu(true);
      if (drawerExpand === false) {
        setDrawerExpand(true);
      }
      setexpandedIndex(index);
    } else {
      setexpandedIndex(index);
      setexpandedIndexSubmenu(-1);
      setOpenSubmenu(false);
      // setDrawerExpand(!drawerExpand);
      if (drawerExpand === true) {
        setDrawerExpand(false);
      }
    }
    if (!subItem || subItem.length < 1) {
      path && navigate(path);
    }
  };

  const showToster = () => {
    toast(
      <div>
        <div className="flex justify-content-between padding-down-15">
          <div className="toast-header">{t("success")}</div>
          <div>
            <CloseIcon className="toast-close" />
          </div>
        </div>
        <div>{t("logOutSuccessfully")}</div>
      </div>,
      {
        className: "toastmsg",
      }
    );
  };

  const selectMenu = (selectMenu: string) => {
    if (selectMenu === "Logout") {
      localStorage.removeItem("user-token");
      localStorage.removeItem("center_code");
      showToster();
      navigate("/auth/login");
    } else if (selectMenu === "Myprofile") {
    } else if (selectMenu === "ChangePassword") {
      navigate("/change-password");
    }
  };

  return (
    <Box
      sx={{ paddingLeft: "70px" }}
      className={programName === "ga" ? "theme-ga" : "theme-sa"}
    >
      <CssBaseline />

      <AppBar
        position="fixed"
        open={drawerExpand}
        style={{
          justifyContent: "space-between",
          flexDirection: "unset",
          zIndex: "2",
        }}
      >
        <Toolbar style={{ padding: "0px 0px 0px 10px" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "1px",
              marginLeft: "0px",
            }}
          >
            {!drawerExpand && (
              <img
                width={49}
                src={programName === "ga" ? LogoGaSmall : Logo2}
                className="side-nav-img"
                alt="side-nav-img"
              />
            )}
          </IconButton>
          <div className="bg-white">
            <BreadcrumbFile />
          </div>
        </Toolbar>

        <div className="app-title-mid"></div>

        <div
          className="bg-white flex center"
          style={{
            padding: "30px",
          }}
        >
          <div
            style={{
              padding: "0 15px 0 0px",
              display: "inherit",
              cursor: "pointer",
            }}
            onClick={handleNotificationDrawerOpen}
          >
            <Badge badgeContent={18} color="error">
              <NotificationsNoneIcon color="action" />
            </Badge>
          </div>

          <div
            style={{
              padding: "0 10px",
              display: "inherit",
              borderLeft: "1px solid #313131",
              borderRight: "1px solid #313131",
            }}
          >
            <img
              src="https://cdn.britannica.com/97/1597-004-05816F4E/Flag-India.jpg"
              style={{ width: "36px", height: "24px", marginRight: "5px" }}
              alt="Flag-India"
            />
            <span>India</span>
          </div>

          <Box sx={{ flexGrow: 0 }} style={{ display: "contents" }}>
            <div
              style={{
                paddingLeft: "10px",
                fontSize: "12px",
              }}
              onClick={handleOpenUserMenu}
            >
              <IconButton sx={{ p: 0 }}>
                <Avatar
                  alt="John Doe"
                  src="/static/images/avatar/1.jpg"
                  sx={{ width: 28, height: 28 }}
                />{" "}
              </IconButton>
            </div>
            <ArrowDropDownIcon
              onClick={handleOpenUserMenu}
              className="pointer"
            />
            <Menu
              className="drop-down"
              sx={{ mt: "45px", borderRadius: "50px" }}
              id="menu-appbar"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <div
                className="border-down"
                style={{ margin: "3px 10px 5px 10px" }}
              >
                <MenuItem onClick={() => selectMenu("Myprofile")}>
                  {t("MyProfile")}
                </MenuItem>
              </div>
              <div
                className="border-down"
                style={{ margin: "3px 10px 5px 10px" }}
              >
                <MenuItem onClick={() => selectMenu("ChangePassword")}>
                  {t("ChangePassword")}
                </MenuItem>
              </div>
              <div style={{ margin: "3px 10px 5px 10px" }}>
                <MenuItem onClick={() => selectMenu("Logout")}>
                  {t("Logout")}
                </MenuItem>
              </div>
            </Menu>
          </Box>
        </div>
      </AppBar>

      <Drawer className="drawer" variant="permanent" open={drawerExpand}>
        <DrawerHeader className="bg">
          {drawerExpand && (
            <IconButton
              onClick={handleDrawerClose}
              style={{
                backgroundColor: "white",
                borderRadius: "6px",
                width: "100%",
              }}
            >
              {theme.direction === "rtl" ? (
                <img
                  src={programName === "ga" ? LogoGaBig : Logo1}
                  alt="Logo"
                  style={{ height: "34px" }}
                />
              ) : (
                <img
                  src={programName === "ga" ? LogoGaBig : Logo1}
                  alt="Logo"
                  style={{ height: "34px" }}
                />
              )}
            </IconButton>
          )}
        </DrawerHeader>
        <Divider />

        <List className="bg" style={{ height: "100vh" }}>
          {Users.map((ele, index) => {
            return (
              <ListItem key={index} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    justifyContent: drawerExpand ? "initial" : "center",
                    px: 2.5,
                  }}
                  className={
                    expandedIndex === index ? "active mainMenuColor" : ""
                  }
                  onClick={() => handleClick(index, ele.subMenu, ele.path)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: drawerExpand ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <span className="menu-icon flex">{ele.icon}</span>
                  </ListItemIcon>
                  <ListItemText
                    className="center-start"
                    primary={<div className="center-start">{ele.name}</div>}
                    sx={{
                      opacity: drawerExpand ? 1 : 0,
                      color: "#fff",
                      height: "10px",
                    }}
                  />
                </ListItemButton>
                {ele.subMenu.length > 0 &&
                  openSubmenu &&
                  expandedIndex === index &&
                  ele.subMenu?.map((val, i) => (
                    <Collapse
                      key={i}
                      in={openSubmenu}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItemButton
                          sx={{ pl: 4 }}
                          onClick={() => handleSubmenuClick(val, i)}
                          className={
                            expandedIndexSubmenu === i
                              ? "active mainMenuColor"
                              : ""
                          }
                        >
                          <ListItemText primary={val && val.name} />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  ))}
              </ListItem>
            );
          })}
        </List>
      </Drawer>
      <Outlet />

      <RightDrawer
        open={notificationDrawerOpen}
        onClose={handleNotificationDrawerClose}
        title={t("notification")}
        content={<BellNotification />}
      />
    </Box>
  );
}
