import { lazy } from "react";

const Login = lazy(() => import("./../auth/login/Login"));
const GetOtp = lazy(() => import("./../auth/forgot-password/GetOTP"));
const PasswordOtp = lazy(() => import("./../auth/forgotpassword/PasswordOtp"));
const NewPassword = lazy(() => import("./../auth/forgotpassword/NewPassword"));
const CreateUser = lazy(() => import("../portal/UserMaster/CreateUser"));
const CreateCourseInstructor = lazy(
  () => import("../portal/Course Instructor/CreateCourseInstructor")
);
const ViewUser = lazy(() => import("../portal/UserMaster/ViewUser"));
const CreateList = lazy(() => import("../portal/Batch/CreateList"));
const UserMaster = lazy(() => import("../portal/UserMaster/UserMaster"));
const CourseInstructor = lazy(
  () => import("../portal/Course Instructor/CourseInstructor")
);
const Dashboard = lazy(() => import("../portal/Dashboard"));
const ViewCourseInstructor = lazy(
  () => import("../portal/Course Instructor/ViewCourseInstructor")
);
const FranchiseGroup = lazy(
  () => import("../portal/franchiseGroup/franchiseGroupList")
);
const Feedback = lazy(() => import("../portal/Feedback/FeedbackForm"));
const CreateGroup = lazy(() => import("../portal/franchiseGroup/CreateGroup"));
const UserRole = lazy(() => import("../portal/UserRole/UserRole"));
const RoleAccess = lazy(() => import("../portal/RoleAccess/RoleAccess"));
const UserMasterHo = lazy(() => import("../portal/UserMasterHo/UserMasterHo"));
const ChangePassword = lazy(
  () => import("../auth/forgotpassword/ChangePassword")
);
const FranchiseMasterHo = lazy(
  () => import("../portal/franchiseMasterHo/FranchiseMasterHo")
);
const RedeemPointOrder = lazy(
  () => import("../portal/RedeemPointOrder/RedeemPointOrder")
);
const StudentInvoice = lazy(
  () => import("../portal/StudentInvoice/StudentInvoice")
);
const InternalPurcheseOrder = lazy(
  () => import("../portal/internalPurchaseOrder/internalPurcheseOrder")
);
const PurchaseReturn = lazy(
  () => import("../portal/purchaseReturn/purcheseReturn")
);
const SmsOrder = lazy(() => import("../portal/smsOrder/smsOrder"));
const CustomerDiscount = lazy(
  () => import("../portal/customerDiscount/customerDiscount")
);
const SendNotificationHo = lazy(
  () => import("../portal/sendNotification/sendNotificationHo")
);
const Payment = lazy(() => import("../portal/payment/payment"));
const ItemMaster = lazy(() => import("../portal/itemMaster/itemMaster"));
const StudentMaster = lazy(
  () => import("../portal/studentMaster/studentMaster")
);
const StudentDetails = lazy(
  () => import("../portal/studentMaster/studentsDetails/studentDetails")
);
const StudentAttendance = lazy(
  () => import("../portal/attendance/studentAttendance")
);
const PaymentsGateway = lazy(
  () => import("../portal/paymentsGateway/paymentsGateway")
);
const CertificateBookingPrinting = lazy(
  () =>
    import("../portal/certificateBookingPrinting/certificateBookingPrinting")
);
const StudentLeadManagement = lazy(
  () => import("../portal/StudentLeadManagement/StudentLeadManagement")
);
const DispatchRedeemedProduct = lazy(
  () => import("../portal/dispatchRedeemedProduct/dispatchRedeemedProduct")
);
const StudentRedeemPointOrder = lazy(
  () => import("../portal/studentRedeemPointOrder/redeemPointOrder")
);
const PaymentSummary = lazy(
  () => import("../portal/paymentSummary/paymentSummary")
);
const Outstanding = lazy(() => import("../portal/outstanding/outstanding"));
const Order = lazy(() => import("../portal/order/order"));
const KitOutstandingHo = lazy(
  () => import("../portal/kitOutstandingHo/kitOutstandingHo")
);
const DashboardHo = lazy(() => import("../portal/dashboardHo"));
const Reports = lazy(() => import("../portal/reports/reports"));
const SendEmailSms = lazy(
  () => import("../portal/sendNotification/sendEmailSMS")
);
const Template = lazy(
  () => import("../portal/sendNotification/template/Template")
);
const SentHistory = lazy(
  () => import("../portal/sendNotification/history/SentHistory")
);
const AdminSettings = lazy(
  () => import("../portal/AdminSetting/AdminSettings")
);
const PromoCode = lazy(() => import("../portal/PromoCode/promoGroupList"));

const DispatchPurchaseOrder = lazy(
  () => import("../portal/DispatchPurchaseOrder/dispatchPurcheseOrder")
);
const CertificateRequest = lazy(
  () => import("../portal/certificateRequest/certificateRequest")
);

const center_code: any = localStorage.getItem("center_code");

export const Routings = [
  {
    path: "/",
    isAuthenticate: false,
    component: <Login />,
  },
  {
    path: "auth/login",
    isAuthenticate: false,
    component: <Login />,
  },
  {
    path: "auth/login?program=sa",
    isAuthenticate: false,
    component: <Login />,
  },
  {
    path: "auth/forgot-password/otp",
    isAuthenticate: false,
    component: <GetOtp />,
  },
  {
    path: "auth/forgot-password/verify-otp",
    isAuthenticate: false,
    component: <PasswordOtp />,
  },
  {
    path: "auth/new-password",
    isAuthenticate: false,
    component: <NewPassword />,
  },
  {
    path: "change-password",
    isAuthenticate: true,
    component: <ChangePassword />,
  },
  {
    path: "batch-list",
    isAuthenticate: true,
    component: <CreateList />,
  },

  {
    path: "course-instructor",
    isAuthenticate: true,
    component: <CourseInstructor />,
  },
  {
    path: "create-course-instructor",
    isAuthenticate: true,
    component: <CreateCourseInstructor />,
  },
  {
    path: "view-course-instructor",
    isAuthenticate: true,
    component: <ViewCourseInstructor />,
  },
  {
    path: "user-master",
    isAuthenticate: true,
    component:
      center_code === "SABG" || center_code === "sabg" ? (
        <UserMasterHo />
      ) : (
        <UserMaster />
      ),
  },
  {
    path: "create-user",
    isAuthenticate: true,
    component: <CreateUser />,
  },
  {
    path: "view-user",
    isAuthenticate: true,
    component: <ViewUser />,
  },
  {
    path: "dashboard",
    isAuthenticate: true,
    component:
      center_code === "SABG" || center_code === "sabg" ? (
        <DashboardHo />
      ) : (
        <Dashboard />
      ),
  },
  {
    path: "franchise-group",
    isAuthenticate: true,
    component:
      center_code === "SABG" || center_code === "sabg" ? (
        <FranchiseGroup />
      ) : (
        ""
      ),
  },
  {
    path: "promo-code",
    isAuthenticate: true,
    component:
      center_code === "SABG" || center_code === "sabg" ? <PromoCode /> : "",
  },
  {
    path: "feedback",
    isAuthenticate: true,
    component:
      center_code === "SABG" || center_code === "sabg" ? <Feedback /> : "",
  },
  {
    path: "admin-settings",
    isAuthenticate: true,
    component:
      center_code === "SABG" || center_code === "sabg" ? <AdminSettings /> : "",
  },
  {
    path: "franchise-master",
    isAuthenticate: true,
    component:
      center_code === "SABG" || center_code === "sabg" ? (
        <FranchiseMasterHo />
      ) : (
        <CreateGroup />
      ),
  },
  {
    path: "student-lead-management",
    isAuthenticate: true,
    component: <StudentLeadManagement />,
  },
  {
    path: "student-invoice",
    isAuthenticate: true,
    component: <StudentInvoice />,
  },
  {
    path: "user-role",
    isAuthenticate: true,
    component: <UserRole />,
  },
  {
    path: "role-access",
    isAuthenticate: true,
    component: <RoleAccess />,
  },
  {
    path: "user-master",
    isAuthenticate: true,
    component: <UserMasterHo />,
  },
  {
    path: "franchise-master",
    isAuthenticate: true,
    component: <FranchiseMasterHo />,
  },
  {
    path: "redeem-point-order",
    isAuthenticate: true,
    component: <RedeemPointOrder />,
  },
  {
    path: "internal-purchese-order",
    isAuthenticate: true,
    component: <InternalPurcheseOrder />,
  },
  {
    path: "purchase-return",
    isAuthenticate: true,
    component: <PurchaseReturn />,
  },
  ,
  {
    path: "sms-order",
    isAuthenticate: true,
    component: <SmsOrder />,
  },
  {
    path: "customer-discount",
    isAuthenticate: true,
    component: <CustomerDiscount />,
  },
  {
    path: "send-notification",
    isAuthenticate: true,
    component:
      center_code === "SABG" || center_code === "sabg" ? (
        <SendNotificationHo />
      ) : (
        ""
      ),
  },
  {
    path: "payment",
    isAuthenticate: true,
    component: <Payment />,
  },
  {
    path: "send-email-sms",
    isAuthenticate: true,
    component: <SendEmailSms />,
  },
  {
    path: "sent-history",
    isAuthenticate: true,
    component: <SentHistory />,
  },
  {
    path: "template",
    isAuthenticate: true,
    component: <Template />,
  },
  {
    path: "item-master",
    isAuthenticate: true,
    component: <ItemMaster />,
  },
  {
    path: "student-master",
    isAuthenticate: true,
    component: <StudentMaster />,
  },
  {
    path: "student-details",
    isAuthenticate: true,
    component: <StudentDetails />,
  },
  {
    path: "student-attendance",
    isAuthenticate: true,
    component: <StudentAttendance />,
  },
  {
    path: "payments-gateway",
    isAuthenticate: true,
    component: <PaymentsGateway />,
  },
  {
    path: "certificate-booking-printing",
    isAuthenticate: true,
    component: <CertificateBookingPrinting />,
  },
  {
    path: "dispatch-redeemed-product",
    isAuthenticate: true,
    component: <DispatchRedeemedProduct />,
  },
  {
    path: "student-redeem-point-order",
    isAuthenticate: true,
    component: <StudentRedeemPointOrder />,
  },
  {
    path: "payment-summary",
    isAuthenticate: true,
    component: <PaymentSummary />,
  },
  {
    path: "outstanding",
    isAuthenticate: true,
    component: <Outstanding />,
  },
  {
    path: "order",
    isAuthenticate: true,
    component: <Order />,
  },
  {
    path: "kit-outstanding",
    isAuthenticate: true,
    component: <KitOutstandingHo />,
  },
  {
    path: "report",
    isAuthenticate: true,
    component: <Reports />,
  },
  {
    path: "dispatch-purchese-order",
    isAuthenticate: true,
    component: <DispatchPurchaseOrder />,
  },
  {
    path: "certificate-request",
    isAuthenticate: true,
    component: <CertificateRequest />,
  },
];
