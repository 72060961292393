import { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import { Routings } from "./route";
import PublicRoute from "./../util/PublicRoute";
import PrivateRoute from "./../util/PrivateRoute";
import ErrorFallback from "../component/ErrorFallback";

function DefaultRoutes() {
  // Need to remove redundant components like Private & Public Routes. Use only one Protected Route
  return (
    <div className="App">
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          window.location.reload();
        }}
      >
        <Suspense fallback={<>Loading...</>}>
          <BrowserRouter>
            <Routes>
              {Routings.map((RouteDetails: any) => (
                <Route
                  path={RouteDetails.path}
                  element={
                    RouteDetails.isAuthenticate ? (
                      <PrivateRoute>{RouteDetails.component}</PrivateRoute>
                    ) : (
                      <PublicRoute>{RouteDetails.component}</PublicRoute>
                    )
                  }
                />
              ))}
            </Routes>
          </BrowserRouter>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

export default DefaultRoutes;
