import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = (props: any) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  const checkUserToken = () => {
    const userToken = localStorage.getItem("user-token");
    if (userToken && userToken !== "undefined" && userToken !== null) {
      setIsLoggedIn(true);
      return navigate("/dashboard");
    } else {
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);
  return <React.Fragment>{!isLoggedIn ? props.children : null}</React.Fragment>;
};

export default ProtectedRoute;
