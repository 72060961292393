import { useState, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import Routes from "./routes";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { userSelector, User, fetchUsers } from "./store/userSlice";
import "./App.scss";

function App() {
  // This is sample code for reference, will be removed later
  const [users, setUsers] = useState<Array<User>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const userState = useAppSelector(userSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoading(userState.loading);
    setError(userState.error);
    setUsers(userState.users);
  }, [userState]);

  const handleFetchUser = () => {
    dispatch(fetchUsers());
  };

  console.log("loading - ", loading);
  console.log("error - ", error);
  console.log("users - ", users);

  // Sample code - Ends here
  // Remove the below 'Fetch Users' button also, this is for reference

  return (
    <div className="App">
      <Toaster />
      <Routes />
      {/* <button style={{ marginLeft:"500px"}} onClick={handleFetchUser}>Fetch Users</button> */}
    </div>
  );
}

export default App;
