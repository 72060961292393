import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translations.json';
import translationGR from './locales/gr/translations.json';

const resources = {
  en: { translations: translationEN },
  gr: { translations: translationGR }
}

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources,
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'gr'];

export default i18n;
