import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./../portal/Layout";

const PrivateRoute = (props: any) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkUserToken = () => {
    console.log("checkUserToken");
    const userToken = localStorage.getItem("user-token");
    console.log("userToken - ", userToken);
    if (!userToken || userToken === "undefined" || userToken === null) {
      setIsLoggedIn(false);
      return navigate("/auth/login");
    } else {
      setIsLoggedIn(true);
      return navigate("/dashboard");
    }
  };

  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  return (
    <React.Fragment>
      {isLoggedIn ? (
        <>
          <Layout />
          {props.children}
        </>
      ) : null}
    </React.Fragment>
  );
};

export default PrivateRoute;
