import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AppState } from '../store/store';

export interface User {
    name: string;
}

export interface UserState {
    loading: boolean;
    users: Array<User>;
    error: string | undefined;
}

const initialState: UserState = {
    loading: false,
    users: [],
    error: undefined
}

export const fetchUsers = createAsyncThunk(
    "users/fetchUsers",
    () => {
        const response = fetch('https://jsonplaceholder.typicode.com/users').then(data => data.json());
        return response;
    }
)

export const userSlice = createSlice({
    name: 'users',
    initialState,
    extraReducers(builder) {
        builder.addCase(fetchUsers.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(fetchUsers.fulfilled, (state, action) => {
            state.loading = false;
            state.users = action.payload;
        })
        builder.addCase(fetchUsers.rejected, (state, action) => {
            state.loading = false;
            state.users = [];
            state.error = action.error.message;
        })
    },
    reducers: {}
})

export const userSelector = (state: AppState) => state.userReducer;
const userReducer = userSlice.reducer

export default userReducer;