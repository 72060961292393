import Drawer from "./../Drawer/Drawer";

const Layout = () => {
  return (
    <>
      <Drawer />
    </>
  );
};
export default Layout;
