import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import InsightsIcon from "@mui/icons-material/Insights";

export default function BellNotification(props: any) {
  // Can be used later

  //  const [open, setOpen] = React.useState(false);

  // const handleDrawerOpen = () => {
  //   setOpen(!open);
  // };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  // type initialType = {
  //   cName: string;
  //   insName: string;
  //   batchName: string;
  // };

  // let initialValue = {
  //   cName: "",
  //   insName: "",
  //   batchName: "",
  // };

  const programName = window.localStorage.getItem("theme");

  return (
    <div className="sidenav-container">
      <div
        className={
          programName === "ga"
            ? "login-theme-ga theme-ga single"
            : "login-theme-sa theme-sa single"
        }
      >
        <div className="flex">
          <div className="color-sec top-center margin-right-10">
            <span className="notification-icon-container center">
              <NotificationsNoneIcon className="icon-size" />
            </span>
          </div>
          <div className="font-12 border-down padding-down-15">
            Prodigy registrations are open and last date to register 30th July
            2022
            <div className="lite-text">7 Jul 2022 11:25 AM</div>
          </div>
        </div>
        <div className="flex margin-up-10">
          <div className="color-sec top-center margin-right-10">
            <span className="notification-icon-container center">
              <NotificationsNoneIcon className="icon-size" />
            </span>
          </div>
          <div className="font-12 border-down padding-down-15">
            Prodigy registrations are open and last date to register 30th July
            2022
            <div className="lite-text">7 Jul 2022 11:25 AM</div>
          </div>
        </div>
        <div className="flex margin-up-10">
          <div className="color-sec top-center margin-right-10">
            <span className="notification-icon-container center">
              <NotificationsNoneIcon className="icon-size" />
            </span>
          </div>
          <div className="font-12 border-down padding-down-15">
            Continuing to build key life skills in children beyond the pandemic.
            <div className="grid-action margin-up-8">
              <PictureAsPdfIcon className="insert-pdf"></PictureAsPdfIcon>
            </div>
            <div className="lite-text">7 Jul 2022 11:25 AM</div>
          </div>
        </div>
        <div className="flex margin-up-10">
          <div className="color-sec top-center margin-right-10">
            <span className="notification-icon-container center">
              <NotificationsNoneIcon className="icon-size" />
            </span>
          </div>
          <div className="font-12 border-down padding-down-15">
            Become a "GLOBAL ART" franchise.
            <div className="margin-up-8">
              <span className="chart-icon-container">
                <InsightsIcon className="white icon-size"></InsightsIcon>
              </span>
            </div>
            <div className="lite-text">7 Jul 2022 11:25 AM</div>
          </div>
        </div>
        <div className="flex margin-up-10">
          <div className="color-sec top-center margin-right-10">
            <span className="notification-icon-container center">
              <NotificationsNoneIcon className="icon-size" />
            </span>
          </div>
          <div className="font-12 border-down padding-down-15">
            Special offer on SIP Abacus parent app.
            <div className="margin-up-8">
              <span className="chart-icon-container">
                <InsightsIcon className="white icon-size"></InsightsIcon>
              </span>
            </div>
            <div className="lite-text">7 Jul 2022 11:25 AM</div>
          </div>
        </div>
        <div className="flex margin-up-10">
          <div className="color-sec top-center margin-right-10">
            <span className="notification-icon-container center">
              <NotificationsNoneIcon className="icon-size" />
            </span>
          </div>
          <div className="font-12 border-down padding-down-15">
            Gift your child a unique experience, offline mega contest.
            <div className="margin-up-8">
              <span className="chart-icon-container">
                <InsightsIcon className="white icon-size"></InsightsIcon>
              </span>
            </div>
            <div className="lite-text">7 Jul 2022 11:25 AM</div>
          </div>
        </div>
        <div className="flex margin-up-10">
          <div className="color-sec top-center margin-right-10">
            <span className="notification-icon-container center">
              <NotificationsNoneIcon className="icon-size" />
            </span>
          </div>
          <div className="font-12 border-down padding-down-15">
            Women’s day celebration.
            <div className="lite-text">7 Jul 2022 11:25 AM</div>
          </div>
        </div>
      </div>
    </div>
  );
}
