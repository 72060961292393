import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function BreadcrumbFile() {
  const { t } = useTranslation();

  return (
    <div>
      {" "}
      <Typography
        variant="h6"
        noWrap
        component="div"
        className="app-tittle app-tittle-div"
      >
        {window.location.pathname === "/dashboard" ? (
          <b> Dashboard</b>
        ) : window.location.pathname === "/batch-list" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Master</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Batch</div>
          </div>
        ) : window.location.pathname === "/user-role" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Master</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">User Role</div>
          </div>
        ) : window.location.pathname === "/role-access" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Master</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Role Access</div>
          </div>
        ) : window.location.pathname === "/franchise-master" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Master</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Franchise Master</div>
          </div>
        ) : window.location.pathname === "/item-master" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Master</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Item Master</div>
          </div>
        ) : window.location.pathname === "/franchise-group" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Master</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Franchise Group</div>
          </div>
        ) : window.location.pathname === "/promo-code" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Master</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Promo Code</div>
          </div>
        ) : window.location.pathname === "/feedback" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Settings</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Feedback</div>
          </div>
        ) : window.location.pathname === "/admin-settings" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Settings</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Admin Settings</div>
          </div>
        ) : window.location.pathname === "/course-instructor" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Master</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Course Instructor</div>
          </div>
        ) : window.location.pathname === "/user-master" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Master</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">user Master</div>
          </div>
        ) : window.location.pathname === "/student-lead-management" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Student</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">{t("studentLeadManagement")}</div>
          </div>
        ) : window.location.pathname === "/student-master" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Student</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Registration/ Master</div>
          </div>
        ) : window.location.pathname === "/student-attendance" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Student</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Student Attendance</div>
          </div>
        ) : window.location.pathname === "/certificate-booking-printing" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Student</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Certificate Booking Printing</div>
          </div>
        ) : window.location.pathname === "/student-invoice" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Student</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Invoice</div>
          </div>
        ) : window.location.pathname === "/payments-gateway" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Student</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Payment Gateway</div>
          </div>
        ) : window.location.pathname === "/payment-summary" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Purchase</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Payment Summary</div>
          </div>
        ) : window.location.pathname === "/student-redeem-point-order" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Purchase</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Redeem Point Order</div>
          </div>
        ) : window.location.pathname === "/dispatch-redeemed-product" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Purchase</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Dispatch Redeemed Product</div>
          </div>
        ) : window.location.pathname === "/dispatch-purchese-order" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Purchase</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Dispatch Purchase Order</div>
          </div>
        ) : window.location.pathname === "/certificate-request" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Student</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Certificate Request</div>
          </div>
        ) : window.location.pathname === "/outstanding" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Purchase</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Outstanding</div>
          </div>
        ) : window.location.pathname === "/order" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Purchase</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Order</div>
          </div>
        ) : window.location.pathname === "/kit-outstanding" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Purchase</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Outstanding</div>
          </div>
        ) : window.location.pathname === "/sms-order" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Purchase</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">SMS Order</div>
          </div>
        ) : window.location.pathname === "/customer-discount" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Purchase</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Customer Discount</div>
          </div>
        ) : window.location.pathname === "/internal-purchese-order" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Purchase</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Internal Purchase Order</div>
          </div>
        ) : window.location.pathname === "/purchase-return" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Purchase</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Purchase Return</div>
          </div>
        ) : window.location.pathname === "/payment" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Purchase</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Payment</div>
          </div>
        ) : window.location.pathname === "/redeem-point-order" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Purchase</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Redeem Point Order</div>
          </div>
        ) : window.location.pathname === "/report" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Report</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Manage Reports</div>
          </div>
        ) : window.location.pathname === "/send-email-sms" ? (
          <div className="flex bolder">
            <div className="center">{t("notification")}</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec"> {t("sendEmailSMS")}</div>
          </div>
        ) : window.location.pathname === "/template" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Notification</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Template</div>
          </div>
        ) : window.location.pathname === "/sent-history" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Notification</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Sent History</div>
          </div>
        ) : window.location.pathname === "/send-notification" ? (
          <div
            className="flex"
            style={{
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            <div className="center">Notification</div>
            <div className="center">
              <ChevronRightIcon />
            </div>
            <div className="center color-sec">Send Notification</div>
          </div>
        ) : null}
      </Typography>
    </div>
  );
}
